import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { cubicOut } from 'svelte/easing';
import type { TransitionConfig } from 'svelte/transition';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

type FlyAndScaleParams = {
	y?: number;
	x?: number;
	start?: number;
	duration?: number;
};

export const flyAndScale = (
	node: Element,
	params: FlyAndScaleParams = { y: -8, x: 0, start: 0.95, duration: 150 }
): TransitionConfig => {
	const style = getComputedStyle(node);
	const transform = style.transform === 'none' ? '' : style.transform;

	const scaleConversion = (
		valueA: number,
		scaleA: [number, number],
		scaleB: [number, number]
	) => {
		const [minA, maxA] = scaleA;
		const [minB, maxB] = scaleB;

		const percentage = (valueA - minA) / (maxA - minA);
		const valueB = percentage * (maxB - minB) + minB;

		return valueB;
	};

	const styleToString = (
		style: Record<string, number | string | undefined>
	): string => {
		return Object.keys(style).reduce((str, key) => {
			if (style[key] === undefined) return str;
			return str + `${key}:${style[key]};`;
		}, '');
	};

	return {
		duration: params.duration ?? 200,
		delay: 0,
		css: (t) => {
			const y = scaleConversion(t, [0, 1], [params.y ?? 5, 0]);
			const x = scaleConversion(t, [0, 1], [params.x ?? 0, 0]);
			const scale = scaleConversion(t, [0, 1], [params.start ?? 0.95, 1]);

			return styleToString({
				transform: `${transform} translate3d(${x}px, ${y}px, 0) scale(${scale})`,
				opacity: t
			});
		},
		easing: cubicOut
	};
};

export const percentageToTextColor = (percentage: number) => {
	if (percentage >= 90) {
		return 'text-green-500';
	} else if (percentage < 90 && percentage >= 10) {
		return 'text-yellow-500';
	} else {
		return 'text-red-500';
	}
};

export const percentageToBgColor = (percentage: number) => {
	if (percentage >= 90) {
		return 'bg-green-50 dark:bg-green-950 border-green-100 dark:border-green-900';
	} else if (percentage < 90 && percentage >= 10) {
		return 'bg-yellow-50 dark:bg-yellow-950 border-yellow-100 dark:border-yellow-900';
	} else {
		return 'bg-red-50 dark:bg-red-950 border-red-100 dark:border-red-900';
	}
};

export function formatDate(date: Date) {
	return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
}

export function formatTime(date: Date) {
	return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
}

export function formatDateTime(date: Date) {
	return formatDate(date) + ' ' + formatTime(date);
}

export function uppercaseFirst(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function daysLeft(date: Date) {
	const now = new Date();
	const diff = date.getTime() - now.getTime();
	return Math.floor(diff / (1000 * 60 * 60 * 24));
}

export function formatNumber(num: number) {
	return num.toLocaleString('en-US');
}

export function getTimeZone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
